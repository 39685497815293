require('./printerPreviewManager.scss');
const React = require('react')
const PropTypes = require('prop-types');
const { connect } = require('react-redux')
const { isEmpty } = require('lodash');

// @ Actions
const PrinterActions = require('actions/contactCenter/printer')
const getHolderConfirmActions = require('actions/mortgageRequest/mortgageRequestHolderConfirmation');

// @ Components
const { REMOVE_STYLE_REGEX } = require('constants/index');
const { RadioButtonGroup } = require('commonsComponents/radioButtonGroup')
const { RadioButton } = require('commonsComponents/radioButton')
const { OKButton } = require('commonsComponents/OKButton')

//@helper
const BrowserUtils = require('utilities/browserUtils');
const { base64ToBlob } = require('utilities/APIRequestHelper');
const { openBase64pdfInNewTab } = require('utilities/pdfDocumentsHelper');

// @ Utils
const DateHelper = require('utilities/dateHelper')
const PersonalDataHelper = require('utilities/personalDataHelper')
const { FormattedText, formatText } = require('core/i18n').i18n
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');
const storage = require('utilities/storage')
const usd = require('utilities/usd');
const { openPDFViewer } = require('utilities/contactCenter/pdfViewer');
const { LOCAL_ENVIRONMENT } = require('constants/index');

const PRINTED_DOCUMENTS_ANSWER = {
    YES: 'YES',
    NO: 'NO',
}
const DEFAULT_OFFICE_PLACE = 'Madrid'

class PrinterPreviewManager extends React.Component {
    constructor(props) {
        super(props)
        this.handleClickShowPrintablePreview = this.handleClickShowPrintablePreview.bind(this)
        this.handleYesNoSignedDocuments = this.handleYesNoSignedDocuments.bind(this)
        this.handleShowPrintableFromProps = this.handleShowPrintableFromProps.bind(this)
    }

    componentWillUnmount() {
        this.props.resetPrintedIndicators();
    }

    getPlaceAndDateInfo() {
        const country = DEFAULT_OFFICE_PLACE
        const currentDate = new Date()
        const day = DateHelper.getDay(currentDate)
        const month = DateHelper.getMonthFullName(DateHelper.getMonth(currentDate))
        const year = DateHelper.getFullYear(currentDate)

        return formatText("officePrinter-datePrintDocument", [country, day, month, year])
    }

    getClientName() {
        return PersonalDataHelper.getFullName(this.props.immProfile);
    }

    handleYesNoSignedDocuments(value) {
        const { printedAndSignedRadioChanged, enableButtonOffice, taggingOnChangeRadioButton } = this.props;
        const taggingValue = value === PRINTED_DOCUMENTS_ANSWER.YES ? 'si' : 'no';
        printedAndSignedRadioChanged(value)
        enableButtonOffice();
        taggingOnChangeRadioButton && taggingOnChangeRadioButton(taggingValue);
    }

    handleTitleOverrider() {
        const { titleOverrider } = this.props;

        if (titleOverrider) {
            return formatText(titleOverrider);
        }
    }

    handleClickShowPrintablePreview() {
        const { openPrinterPreview, revolvingOfficePrint, togglePrintableStyle, customPrintDocument = '', bodyClass = '', printDocumentTranslations = null} = this.props;
        const url =  `${window.__ENV__ === LOCAL_ENVIRONMENT?"":"/ok-ui-frame-es-cc"}/printerPreview.html?sessionId=${usd.getSessionId()}`;

        openPrinterPreview();
        let elementToPrint = customPrintDocument ? customPrintDocument : window.document.getElementById('printable-wizard');
        if (!elementToPrint) {
            elementToPrint = window.document.getElementsByClassName('wizard')[0];
        }

        if (elementToPrint) {
            storage.localStorage.set('printableParametersContent', togglePrintableStyle ? elementToPrint.outerHTML.replace(REMOVE_STYLE_REGEX, ``) : customPrintDocument ? elementToPrint : elementToPrint.outerHTML);
            storage.localStorage.set('bodyClass', bodyClass);
        }

        storage.localStorage.set('printableParametersTranslations', {
            translations: {
                documentInformation: this.handleTitleOverrider(),
                documentNotes: formatText("officePrinter-documentInformationNotes"),
                signDate: this.getPlaceAndDateInfo(),
                clientName: `${formatText("officePrinter-printerSign")} ${this.getClientName()}`,
                buttonPrint: formatText("officePrinter-printButton"),
                buttonBack: formatText("officePrinter-backButton"),
                socialAddress: formatText("officePrinter-socialAddress"),
                printerLegalText: formatText("officePrinter-printerLegalText"),
                gdpr: {
                    yes: formatText("gdpr-checkboxYes"),
                    no: formatText("gdpr-checkboxNo"),
                    noAnswer: formatText("gdpr-checkboxNoAnswer"),
                },
                ...printDocumentTranslations,
            }
        })

        window.open(url, '_blank');

        if (revolvingOfficePrint && revolvingOfficePrint.getIn(['inneHasBeenFetched'])) {
            const insuranceUrlTmp = revolvingOfficePrint.getIn(['insuranceUrl']);
            if(insuranceUrlTmp) {
                insuranceUrlTmp.forEach((docUrl) => {
                    window.open(docUrl.getIn(['link']), '_blank');
                });
            }
            this.handlePrintPdf(revolvingOfficePrint, 'inne', 'inne');
            this.handlePrintPdf(revolvingOfficePrint, 'document', 'document');
        }
    }

    handlePrintPdf(doc, type, customId) {
        const pdfFile = doc.getIn([`${type}PDF`, 'base64string']);

        if (pdfFile && !isEmpty(pdfFile)) {
            const newPdfBlob = new Blob([base64ToBlob(pdfFile)], {type: "application/pdf"});

            if (BrowserUtils.browserIsIE()) {
                window.navigator.msSaveOrOpenBlob(newPdfBlob);
            } else {
                openPDFViewer(newPdfBlob, customId);
            }
        }
    }

    buildErrorMessage() {
        const { immPrinter } = this.props

        if (immPrinter.get('printRequired')) {
            return (
                <p id="msg_signaturePatternCodeInput_inputRequired" className="signature-pattern-code-input__error">
                    <FormattedText value="officePrinter-printRequired" />
                </p>
            )
        } else if (immPrinter.get('printedAndSignedStatusRequired')) {
            return (
                <p id="msg_signaturePatternCodeInput_inputRequired" className="signature-pattern-code-input__error">
                    <FormattedText value="officePrinter-printedAndSignedRequired" />
                </p>
            )
        }

        return;
    }

    buildPrintedDocumentsConfirmation() {
        return (
            <div className="no-print-out">
                <div className="signature-pattern__printed-documents-question">
                    <FormattedText value="officePrinter-confirmPrintedDocuments" />
                </div>
                <RadioButtonGroup
                    onChange={this.handleYesNoSignedDocuments}
                    selectedValue={this.props.immPrinter.get('documentsPrintedAndSigned')}
                    idPrefix="radioPrintedDocument"
                    isHorizontal={true}
                    name="radioPrintedDocument">
                    <RadioButton label={formatText('officePrinter-yesRadioOption')} value={PRINTED_DOCUMENTS_ANSWER.YES} className="signature-pattern__printed-documents-radio" />
                    <RadioButton label={formatText('officePrinter-noRadioOption')} value={PRINTED_DOCUMENTS_ANSWER.NO} className="signature-pattern__printed-documents-radio" />
                </RadioButtonGroup>
            </div>
        )
    }

    handleShowPrintableFromProps() {
        const { pdfToShow, pdfToShowError, openPrinterPreview, isPdfToShowBase64 } = this.props;
        if (!pdfToShowError) {
            if (isPdfToShowBase64) {
                openBase64pdfInNewTab(pdfToShow);
            } else {
                const newPdfBlob = new Blob([pdfToShow], {type: "application/pdf"});
                const blobURL = window.URL.createObjectURL(newPdfBlob);
                window.open(blobURL, '_blank');
            }
        }
        openPrinterPreview();
    }

    render() {
        const { immPrinter, pdfToShow, pdfToShowError, pdfToShowFetching, isEasyLoanTypeAB, openPrinterPreview } = this.props
        isEasyLoanTypeAB && openPrinterPreview();
        const printedDocumentsConfirmationSection = immPrinter.get('isShowingPreview') || isEasyLoanTypeAB ?
            this.buildPrintedDocumentsConfirmation() : undefined
        const errorMessage = this.buildErrorMessage()
        const printContent = (
            <div className="printable-signature-pattern__container no-print-out">
                {!isEasyLoanTypeAB && <div className="printable-signature-pattern__print-button">
                    <a onClick={pdfToShow || pdfToShowError ? this.handleShowPrintableFromProps : this.handleClickShowPrintablePreview}>
                        <OKButton loading={pdfToShowFetching} >
                            <FormattedText value="officePrinter-printPendingDocumentsButton" />
                        </OKButton>
                    </a>
                </div>
                }
                { printedDocumentsConfirmationSection }
                { errorMessage }
            </div>
        )

        return (
            isPrintOfficeMode() ? printContent : null
        )
    }
}

PrinterPreviewManager.propTypes = {
    openPrinterPreview: PropTypes.func.isRequired,
    resetPrintedIndicators: PropTypes.func.isRequired,
    printedAndSignedRadioChanged: PropTypes.func.isRequired,
    immProfile: PropTypes.object.isRequired,
    immPrinter: PropTypes.object.isRequired,
    isPdfToShowBase64: PropTypes.bool,
    titleOverrider: PropTypes.string,
    revolvingOfficePrint: PropTypes.object,
    pdfToShow: PropTypes.string,
    pdfToShowFetching: PropTypes.bool,
    pdfToShowError: PropTypes.bool,
    togglePrintableStyle: PropTypes.bool,
    customPrintDocument: PropTypes.string,
    bodyClass: PropTypes.string,
    printDocumentTranslations: PropTypes.object,
    isEasyLoanTypeAB : PropTypes.bool,
    taggingOnChangeRadioButton: PropTypes.func,
}

PrinterPreviewManager.defaultProps = {
    isEasyLoanTypeAB: false,
    taggingOnChangeRadioButton: () => {},
}

module.exports.constructor = PrinterPreviewManager
module.exports.PrinterPreviewManager = connect(state => ({
    immProfile: state.profile,
    immPrinter: state.printer,
    revolvingOfficePrint: state.revolvingDocumentsPdfs,
}), {
    openPrinterPreview: PrinterActions.openPrinterPreview,
    resetPrintedIndicators: PrinterActions.resetPrintedIndicators,
    printedAndSignedRadioChanged: PrinterActions.printedAndSignedRadioChanged,
    enableButtonOffice: getHolderConfirmActions.enableButtonOffice,
})(PrinterPreviewManager)
