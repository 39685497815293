require('./signaturePattern.scss');

// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const classnames = require('classnames');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const PropTypes = require('prop-types');

// @ utilities
const {
    createDigitsEnumeration,
    getNumberOfPositionsAsString,
    getStringFromVOandTrustedDevice
} = require('utilities/signaturePatternHelper');
const { FormattedText, formatText } = require('core/i18n').i18n;
const { recordLinkV2 } = require("utilities/tagging");

// @ actions
const signaturePattern = require('actions/signaturePattern');

// @ components
const SignaturePatternSMSInput = require('./signaturePatternSMSInput/signaturePatternSMSInput');
const SignaturePatternCodeInput = require('./signaturePatternCodeInput/signaturePatternCodeInput');
const { LoadingSection } = require('commonsComponents/loadingSection');
const { OKButton } = require('components/commons/OKButton/OKButton');
const { OKMessage } = require('commonsComponents/OKMessage');
const BiocatchExceptionModal = require('components/biocatchExceptionModal/biocatchExceptionModal');
const BlockInfo = require('components/contactCenter/blockInfo/blockInfo');
const ContactCenterRender = require('components/conditionalRender/contactCenterRender')
const { PrinterPreviewManager } = require('components/contactCenter/printer/printerPreviewManager');

// @ constants
const {
    BLOCKED_OTP,
    EXCLUDED_PUSH_OPERATION_CODES,
    EXPIRED_FLOW,
    WEALTH_OPERATIONAL_CODES
} = require('constants/signaturePattern');
const { SENDING_PN_OTP, OTP_VITUAL_OFFICE_CHECK, WEALTH_OTP_PHONE } = require('constants/flagBasedFeatures');
const { LINK_CLICK_ACTION_EVENT } = require('constants/tagging');

class SignaturePattern extends React.Component {
    constructor(props) {
        super(props);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleResendClick = this.handleResendClick.bind(this);
    }

    componentDidMount() {
        const {
            processRequestConfiguration,
            operationType,
            operationParams,
            setWithValidateButton,
            withValidateButton,
            customEventTag,
            customCategoryError
        } = this.props;
        const tagging = { customEventTag: customEventTag, customCategoryError }
        setWithValidateButton(withValidateButton);
        processRequestConfiguration(operationType, operationParams, tagging);
    }

    componentDidUpdate() {
        const {
            restartProcessRequestConfiguration,
            operationType,
            operationParams,
            signaturePattern,
            customComponentDidUpdate
        } = this.props;
        const SMSError = signaturePattern.get('isFetchingSMSCodeError');

        if (SMSError === EXPIRED_FLOW || SMSError === BLOCKED_OTP) {
            restartProcessRequestConfiguration(operationType, operationParams, SMSError);
        }
        if (customComponentDidUpdate) {
            customComponentDidUpdate();
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleValidateDigitInput({newDigit, position}) {
        const { validateDigitInput } = this.props;
        validateDigitInput({newDigit, position});
    }

    handleResendClick() {
        const {
            immFlagBasedFeatures,
            operationParams,
            operationType,
            requestTrustedDeviceInfo,
            restartProcessRequestConfiguration,
            taggingDataLink,
            onResendClick,
            customCategoryEvent
        } = this.props;
        restartProcessRequestConfiguration(operationType, operationParams);
        if (!__CONTACT_CENTER__ && immFlagBasedFeatures.get(SENDING_PN_OTP)) {
            requestTrustedDeviceInfo();
        }
        if (onResendClick) {
            onResendClick()
        } else {
            recordLinkV2({
                categoriaEvento: customCategoryEvent ? customCategoryEvent : window.utag_last_name,
                accionEvento: LINK_CLICK_ACTION_EVENT,
                etiquetaEvento: formatText('signaturePattern-resendSMS'),
                ...(taggingDataLink && taggingDataLink)
            }); 
        }    
    }
    buildLoading() {
        const { isInVerticalWizard } = this.props
        if (__CONTACT_CENTER__ && isInVerticalWizard) {
            return (
                <div>
                    <p className="signature-pattern__loading-message-block">
                        <FormattedText
                            className="signature-pattern__loading-message"
                            value="signaturePattern-loadingMessage"
                        />
                    </p>
                    <LoadingSection />
                </div>
            );
        }
        return <LoadingSection />;
    }

    buildSignaturePatternCodeInput() {
        const {
            disabled,
            immFlagBasedFeatures,
            isInVerticalWizard,
            isResetSignature,
            processSignaturePassCodeWithoutCallback,
            signaturePattern,
            validateDigitInput,
            withValidateButton,
            taggingOnKeyPress,
        } = this.props;

        if (!signaturePattern.get('requiredDigits')) return null;

        if (__CONTACT_CENTER__ && isInVerticalWizard) {
            return (
                <div>
                    <BlockInfo>
                        <FormattedText
                            className="signature-pattern__confirmation-script-title"
                            value="signaturePattern-signaturePatternConfirmationScriptTitle"
                        />
                        <FormattedText
                            className="signature-pattern__confirmation-script"
                            value="signaturePattern-signaturePatternConfirmationScript"
                            injectedStrings={[ getNumberOfPositionsAsString(signaturePattern.get('requiredDigits').size), createDigitsEnumeration(signaturePattern.get('requiredDigits').toJS()) ]}
                        />
                    </BlockInfo>
                    <div key="signature-pattern-code-key-0" className="signature-pattern__code-input-block">
                        <SignaturePatternCodeInput
                            immSignaturePattern={signaturePattern}
                            handleProcessSignaturePassCodeWithoutCallback={processSignaturePassCodeWithoutCallback}
                            validateDigitInput={validateDigitInput}
                            disabled={disabled}
                            isInVerticalWizard
                            isResetSignature={isResetSignature}
                            taggingOnKeyPress={taggingOnKeyPress}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div key="signature-pattern-code-key-0">
                <SignaturePatternCodeInput
                    immFlagBasedFeatures={immFlagBasedFeatures}
                    immSignaturePattern={signaturePattern}
                    handleProcessSignaturePassCodeWithoutCallback={processSignaturePassCodeWithoutCallback}
                    validateDigitInput={validateDigitInput}
                    disabled={disabled}
                    withValidateButton={withValidateButton}
                    displayTitle={this.props.displayTitle}
                    isResetSignature={isResetSignature}
                    taggingOnKeyPress={taggingOnKeyPress}
                />
            </div>
        );
    }

    buildDelayedSMSBlock() {
        const { disabled } = this.props;
        const signatureHelpPhone = (__CONTACT_CENTER__) ? null : <FormattedText value="signaturePattern-delayedSMS2" />;

        return (
            <p>
                <FormattedText value="signaturePattern-delayedSMS1" />
                <OKButton
                    modifier="primary"
                    onClick={this.handleResendClick}
                    id="btn_signaturePattern_resend_delayed"
                    disabled={disabled}
                >
                    <FormattedText value="signaturePattern-resendSMS" />
                </OKButton>
                {signatureHelpPhone}
            </p>
        );
    }

    buildSignaturePatternSMSInput() {
        const {
            confirmationCodeSubtitle,
            delayedMessageForMailOrSMS,
            disabled,
            handleOnChangeSMS,
            immFlagBasedFeatures,
            immSignaturePatternVirtualOffices,
            isInVerticalWizard,
            isWealth,
            operationType,
            showWarningSMS,
            signaturePattern,
            onSMSInputClick,
        } = this.props;
        const wealthOtpFlag = immFlagBasedFeatures.get(WEALTH_OTP_PHONE);

        if (!signaturePattern.get('requiredSMS')) return null;

        if (__CONTACT_CENTER__ && isInVerticalWizard) {
            const delayedSMSBlock = this.buildDelayedSMSBlock();
            return (
                <div>
                    <BlockInfo>
                        <p>
                            <FormattedText value="signaturePattern-otpConfirmationScript1" />
                        </p>
                        <p>
                            <FormattedText value="signaturePattern-otpConfirmationScript2" />
                        </p>
                    </BlockInfo>
                    <div className="signature-pattern__sms-input-block">
                        <SignaturePatternSMSInput
                            key="signature-pattern-code-key-1"
                            handleOnChangeSMS={handleOnChangeSMS}
                            showWarningSMS={showWarningSMS}
                            immSignaturePattern={signaturePattern}
                            disabled={disabled}
                        />
                        {delayedSMSBlock}
                    </div>
                </div>
            );
        }

        const delayedMessage1 = EXCLUDED_PUSH_OPERATION_CODES.includes(operationType) ?
            delayedMessageForMailOrSMS : getStringFromVOandTrustedDevice(
            immFlagBasedFeatures.get(OTP_VITUAL_OFFICE_CHECK),
            immSignaturePatternVirtualOffices,
            {
                push: 'signaturePattern-delayedSMS1Trusted',
                generic: 'signaturePattern-delayedSMS1Generic',
                sms: delayedMessageForMailOrSMS
            }
        )

        const delayedMessage2 = (wealthOtpFlag && (WEALTH_OPERATIONAL_CODES.includes(operationType) || isWealth))
            ? "signaturePattern-delayedSMS2Inversions"
            : "signaturePattern-delayedSMS2"
        return (
            <div>
                <SignaturePatternSMSInput
                    key="signature-pattern-code-key-1"
                    handleOnChangeSMS={handleOnChangeSMS}
                    showWarningSMS={showWarningSMS}
                    immSignaturePattern={signaturePattern}
                    immSignaturePatternVirtualOffices={immSignaturePatternVirtualOffices}
                    immFlagBasedFeatures={immFlagBasedFeatures}
                    operationType={operationType}
                    confirmationCodeSubtitle={confirmationCodeSubtitle}
                    displayTitle={this.props.displayTitle}
                    disabled={disabled}
                    handleInputClick={onSMSInputClick}
                />
                <p>
                    <FormattedText value={delayedMessage1} />
                    <OKButton
                        modifier="link-emphasize"
                        onClick={this.handleResendClick}
                        id="btn_signaturePattern_resend_delayed"
                        disabled={disabled}
                    >
                        <FormattedText value="signaturePattern-resendSMS" />
                    </OKButton>
                    <FormattedText value={delayedMessage2} />
                </p>
            </div>
        );
    }

    render() {
        const {
            customClassName,
            signaturePattern,
            printTitleValue,
            pdfToShow,
            isPdfToShowBase64,
            pdfToShowFetching,
            pdfToShowError,
            togglePrintableStyle,
            customPrintDocument,
            bodyClass,
            printDocumentTranslations,
            isOverDraft,
            isEasyLoanTypeAB,
            taggingOnChangeRadioButton
        } = this.props;
        const customClass = classnames('signature-pattern', { [customClassName]: !!customClassName });

        if (signaturePattern.get('isFetchingConfig')) {
            return this.buildLoading();
        }

        if (signaturePattern.get('isFetchingConfigError') && signaturePattern.get('isFetchingConfigForbiddenError')) {
            return (
                <OKMessage title={formatText('signaturePattern-forbiddenErrorTitle')} type="important" >
                    <FormattedText value="signaturePattern-forbiddenErrorMessage" />
                </OKMessage>
            );
        }
        if (signaturePattern.get('isFetchingConfigError')) {
            return (
                <p>
                    <FormattedText value="signaturePattern-configFetchError01" />
                    <OKButton
                        modifier="link-emphasize"
                        onClick={this.handleResendClick}
                        id="btn_signaturePattern_resend_error"
                    >
                        <FormattedText value="signaturePattern-refetchConfig" />
                    </OKButton>
                    <FormattedText value="signaturePattern-configFetchError02" />
                </p>
            );
        }

        let biocatchExceptionModal;
        if (!__CONTACT_CENTER__ && signaturePattern.get('showBiocatchExceptionModal')) {
            biocatchExceptionModal = (<BiocatchExceptionModal biocatchError={signaturePattern.get('biocatchError')} />);
        }
        const signatureCodeSection = this.buildSignaturePatternCodeInput();

        const smsSection = this.buildSignaturePatternSMSInput();

        return (
            <div>
                {isOverDraft ? null:(
                <ContactCenterRender>
                    <PrinterPreviewManager
                        pdfToShow={pdfToShow}
                        isPdfToShowBase64={isPdfToShowBase64}
                        titleOverrider={printTitleValue ? printTitleValue  : undefined}
                        pdfToShowFetching={pdfToShowFetching}
                        pdfToShowError={pdfToShowError}
                        togglePrintableStyle={togglePrintableStyle}
                        customPrintDocument={customPrintDocument}
                        bodyClass={bodyClass}
                        printDocumentTranslations={printDocumentTranslations}
                        isEasyLoanTypeAB={isEasyLoanTypeAB}        
                        taggingOnChangeRadioButton={taggingOnChangeRadioButton}             
                    />
                </ContactCenterRender>
                )}

                <section className={customClass}>
                    <div className="signature-pattern__content">
                        {signatureCodeSection}
                        {smsSection}
                    </div>
                </section>
                {biocatchExceptionModal}
            </div>
        );
    }
}

SignaturePattern.propTypes = {
    // public props
    operationType: PropTypes.number.isRequired,
    operationParams: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    customClassName: PropTypes.string,
    displayTitle: PropTypes.bool,
    isInVerticalWizard: PropTypes.bool,
    disabled: PropTypes.bool,
    isResetSignature: PropTypes.bool,
    isWealth: PropTypes.bool,
    pdfToShow: PropTypes.string,
    isPdfToShowBase64: PropTypes.bool,
    pdfToShowFetching: PropTypes.bool,
    pdfToShowError: PropTypes.bool,
    togglePrintableStyle: PropTypes.bool,
    customPrintDocument: PropTypes.string,
    bodyClass: PropTypes.string,
    printDocumentTranslations: PropTypes.object,

    // private props
    signaturePattern: PropTypes.object.isRequired,
    immFlagBasedFeatures: PropTypes.object,
    immPrinter: PropTypes.object,
    updateDigitsForValidation: PropTypes.func,
    getRequiredDigits: PropTypes.func,
    processRequestConfiguration: PropTypes.func,
    restartProcessRequestConfiguration: PropTypes.func,
    handleOnChangeSMS: PropTypes.func,
    showWarningSMS: PropTypes.func,
    customCategoryEvent: PropTypes.string,
    submitSignaturePassCode: PropTypes.func,
    processRequestCode: PropTypes.func,
    validateDigitInput: PropTypes.func,
    onSMSInputClick: PropTypes.func,
    reset: PropTypes.func,
    requestTrustedDeviceInfo: PropTypes.func,
    validateVirtualOffice: PropTypes.func,
    processSignaturePassCodeWithoutCallback: PropTypes.func,
    immSignaturePatternVirtualOffices: PropTypes.func,
    customComponentDidUpdate: PropTypes.func,
    delayedMessageForMailOrSMS: PropTypes.string,
    delayedMessageForMailOrSMSTrusted: PropTypes.string,
    confirmationCodeSubtitle: PropTypes.string,
    withValidateButton: PropTypes.bool,
    setWithValidateButton: PropTypes.func,
    printTitleValue: PropTypes.string,
    taggingDataLink: PropTypes.object,
    onResendClick: PropTypes.func,
    isEasyLoanTypeAB: PropTypes.bool,
    taggingOnKeyPress: PropTypes.func,
    taggingOnChangeRadioButton: PropTypes.func,
};

SignaturePattern.defaultProps = {
    isInVerticalWizard: false,
    disabled: false,
    delayedMessageForMailOrSMS: 'signaturePattern-delayedSMS1',
    displayTitle: true,
    withValidateButton: !__CONTACT_CENTER__,
    isResetSignature: false,
    isWealth: false,
    pdfToShow: null,
    isPdfToShowBase64: true,
    pdfToShowFetching: false,
    pdfToShowError: false,
    togglePrintableStyle: false,
    customCategoryEvent: undefined,
    customEventTag: undefined,
    customCategoryError: undefined,
    customPrintDocument: '',
    bodyClass: '',
    printDocumentTranslations: null,
    onResendClick: () => {},
    isOverDraft: false,
    isEasyLoanTypeAB: false,
    taggingOnKeyPress: () => {},
    taggingOnChangeRadioButton: () => {},
}

module.exports.constructor = SignaturePattern;
module.exports.SignaturePattern = connect(
    state => ({
        immFlagBasedFeatures: state.flagBasedFeatures,
        signaturePattern: state.signaturePattern,
        immSignaturePatternVirtualOffices: state.signaturePatternVirtualOffices,
        immPrinter: state.printer,
    }),
    {
        processRequestConfiguration: signaturePattern.processRequestConfiguration,
        restartProcessRequestConfiguration: signaturePattern.restartProcessRequestConfiguration,
        processSignaturePassCodeWithoutCallback: signaturePattern.processSignaturePassCodeWithoutCallback,
        handleOnChangeSMS: signaturePattern.handleOnChangeSMS,
        requestTrustedDeviceInfo: signaturePattern.requestTrustedDeviceInfo,
        validateVirtualOffice: signaturePattern.validateVirtualOffice,
        showWarningSMS: signaturePattern.showWarningSMS,
        validateDigitInput: signaturePattern.validateDigitInput,
        reset: signaturePattern.reset,
        setWithValidateButton: signaturePattern.setWithValidateButton
    }
)(SignaturePattern);
