require('./preLeaveModalComponent.scss');

// @ vendor
const React = require('react');
const trim = require('lodash/string/trim');
const PropTypes = require('prop-types');
const { withRouter, Prompt } = require('react-router-dom');

// @ components
const OKModal = require('components/commons/OKModal/OKModal');

// @helpers
const { recordCancellation } = require('utilities/tagging');

// @constants
const { BROKER_LOGIN_URL, MYPROFILE_BROKER_ERROR_URL, WITHOUT_NEW_MONEY_DEPOSIT_URL } = require('constants/index');

// @utils
import { recordLinkV2 } from 'utilities/tagging';

class PreLeaveModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLeavingWarning: false,
            isLoadingPrimary: false
        };

        this.handlePrimaryButtonClick = this.handlePrimaryButtonClick.bind(this);
        this.handleSecondaryButtonClick = this.handleSecondaryButtonClick.bind(this);
        this.routerWillLeave = this.routerWillLeave.bind(this);
        this.handleCancelLeave = this.handleCancelLeave.bind(this);
        this.handleConfirmLeave = this.handleConfirmLeave.bind(this);
    }

    componentWillMount() {
        window.modalInstance = this;
    }

    componentWillUnmount() {
        window.modalInstance = null;
    }

    handleCancelLeave(isCloseButtonClick) {
        const { closeModalNewWizard, isOkNewWizard, onCloseButtonClick, taggingCategoryEvent } = this.props;
        recordLinkV2({
            categoriaEvento: taggingCategoryEvent ? taggingCategoryEvent : window.utag_data.tipoProceso,
            accionEvento: 'modal cancelar solicitud',
            etiquetaEvento: 'cerrar',
        });
        if (!isOkNewWizard) {
            isCloseButtonClick && onCloseButtonClick && onCloseButtonClick();
            this.setState({
                showLeavingWarning: false,
                nextLocation: false
            })
        } else {
            closeModalNewWizard();
        }
    }

    handleConfirmLeave() {
        const { onConfirmLeave, taggingCategoryEvent, location, history } = this.props;
        const { externalLink, nextLocation } = this.state;

        recordLinkV2({
            categoriaEvento: taggingCategoryEvent ? taggingCategoryEvent : window.utag_data.tipoProceso,
            accionEvento: 'modal cancelar solicitud',
            etiquetaEvento: 'cancelar',
        });

        // Keep this if at the top of the function
        if (externalLink) {
            window.location.href = externalLink;
            return;
        }
        // Do not move the following declaration to the top of the function
        const finalNextLocation = nextLocation || location.pathname;

        if (onConfirmLeave) {
            onConfirmLeave();
        }

        //if the view that wil get rendered when the new route is resolved
        //doesn't remove this modal, we need to be sure we clean the state
        //for a fresh start

        recordCancellation();
        this.setState({
            showLeavingWarning: false,
            nextLocation: false
        });

        history.push(finalNextLocation);
    }

    handlePrimaryButtonClick() {
        const { leftButtonConfig, cancelModalNewWizard, isOkNewWizard, onCancelRecord } = this.props;

        if (!isOkNewWizard) {
            if (leftButtonConfig) {
                const finalCallback = leftButtonConfig.redirect ? this.handleConfirmLeave : this.handleCancelLeave;

                if (!!leftButtonConfig.callback) {
                    leftButtonConfig.callback(finalCallback);
                    this.setState({ isLoadingPrimary: !!leftButtonConfig.callback });
                } else {
                    finalCallback();
                }
            } else { // standar behaviour
                if (onCancelRecord) {
                    onCancelRecord();
                } else {
                this.handleConfirmLeave();
                }
            }
        } else {
            cancelModalNewWizard();
        }

    }

    handleSecondaryButtonClick() {
        const { rightButtonConfig, closeModalNewWizard, isOkNewWizard, onContinueLater, taggingCategoryEvent } = this.props;

        if (!isOkNewWizard) {
            if (rightButtonConfig) {
                const finalCallback = rightButtonConfig.redirect ? this.handleConfirmLeave : this.handleCancelLeave;

                if (!!rightButtonConfig.callback) {
                    rightButtonConfig.callback(finalCallback);
                } else {
                    finalCallback();
                }
            } else { // standar behaviour
                if (onContinueLater) {
                    recordLinkV2({
                        categoriaEvento: taggingCategoryEvent ? taggingCategoryEvent : window.utag_data.tipoProceso,
                        accionEvento: "modal cancelar solicitud",
                        etiquetaEvento: "continuar mas tarde"
                    });
                    onContinueLater();
                } else {
                    this.handleCancelLeave();
                }
            }
        } else {
            closeModalNewWizard();
        }
    }

    mustIgnoreLocation(location) {
        const { allowRedirectTo } = this.props;
        const ignoredLocations = [
            BROKER_LOGIN_URL,
            MYPROFILE_BROKER_ERROR_URL,
            WITHOUT_NEW_MONEY_DEPOSIT_URL
        ];
        allowRedirectTo && ignoredLocations.push(allowRedirectTo);
        const isLocationIgnored = ignoredLocations.indexOf(trim(location)) !== -1;

        return isLocationIgnored;
    }

    routerWillLeave(nextLocation) {
        const { isReady, beforeLeaving, allowRedirectTo } = this.props;
        const { showLeavingWarning } = this.state;
        let allowRedirectPaths = ['/login'];
        allowRedirectPaths.push(allowRedirectTo);

        this.setState({ showLeavingWarning: false });
        // Once redirect intention is confirmed, avoid checking again
        if (isReady() || (nextLocation && this.mustIgnoreLocation(nextLocation.pathname))) {
            beforeLeaving && beforeLeaving();
            return true;
        } else if (!showLeavingWarning && nextLocation && nextLocation.pathname) {
            // If user has done some changes, show warning before leaving and store new potential path
            this.setState({
                showLeavingWarning: true,
                nextLocation: nextLocation.pathname + nextLocation.search
            });
            return false;
        } else if (!showLeavingWarning && nextLocation && nextLocation.externalLink) {
            this.setState({
                showLeavingWarning: true,
                externalLink: nextLocation.externalLink
            })
            return false;
        }
    }

    render() {
        const {






            acceptText,
            cancelText,
            confirmationMessage,
            confirmationTitle,
            isOkNewWizard,
            leftButtonConfig,
            rightButtonConfig,
            className
        } = this.props;
        const { showLeavingWarning, isLoadingPrimary } = this.state;

        let leavingWarningMessage = null;

        if (showLeavingWarning || isOkNewWizard) {
            const primaryButtonText = leftButtonConfig && leftButtonConfig.text ? leftButtonConfig.text : acceptText;
            const secondaryButtonText = rightButtonConfig && rightButtonConfig.text ? rightButtonConfig.text : cancelText;

            leavingWarningMessage = (
                <OKModal
                    description={confirmationMessage}
                    descriptionAlignment="center"
                    footerType="action"
                    isOpen={true}
                    onCloseCallback={() => this.handleCancelLeave(true)}
                    onPrimaryButtonClick={this.handlePrimaryButtonClick}
                    onSecondaryButtonClick={this.handleSecondaryButtonClick}
                    primaryButtonText={primaryButtonText}
                    primaryButtonLoading={isLoadingPrimary}
                    secondaryButtonText={secondaryButtonText}
                    shouldCloseOnOverlayClick={false}
                    title={confirmationTitle}
                    titleAlignment="center"
                    overlayClassName={className}
                    analyticsDataView={{
                        categoriaEvento: window.utag_data.tipoProceso,
                        accionEvento: "modal cancelar solicitud",
                        etiquetaEvento: "vista"
                    }}
                />
            );
        }

        return (
            <React.Fragment>
                <Prompt message={this.routerWillLeave} />
                {leavingWarningMessage}
            </React.Fragment>
        );
    }
}

PreLeaveModalComponent.propTypes = {
    acceptText: PropTypes.string.isRequired,
    //beforeLeaving is a callback to be called when redirection occurs without
    //asking for confirmation
    beforeLeaving: PropTypes.func,
    confirmationMessage: PropTypes.string.isRequired,
    confirmationTitle: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    //isReady is a callback that return whether the confirmation popup should
    //be shown or not
    isReady: PropTypes.func,
    location: PropTypes.object.isRequired,
    //onConfirmLeave is a callback to be called when user confirms redirection
    onConfirmLeave: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    allowRedirectTo: PropTypes.string,
    /* Buttons custom behaviour example */
    // configurationExample = {
    //     text: formatText('configurationExample-configurationExample'),
    //     callback: this.configurationExample,
    //     redirect: true
    // };
    leftButtonConfig: PropTypes.shape({ // overrides button appareance and behaviour
        text: PropTypes.string,
        callback: PropTypes.func,
        redirect: PropTypes.bool
    }),
    rightButtonConfig: PropTypes.shape({ // overrides button appareance and behaviour
        text: PropTypes.string,
        callback: PropTypes.func,
        redirect: PropTypes.bool
    }),
    className: PropTypes.string,
    isOkNewWizard: PropTypes.bool,
    closeModalNewWizard: PropTypes.func,
    cancelModalNewWizard: PropTypes.func,
    history: PropTypes.object.isRequired,
};

PreLeaveModalComponent.defaultProps = {
    isReady: () => false,
    isOkNewWizard: false,
    closeModalNewWizard: () => { },
    cancelModalNewWizard: () => { },
    className: "",
}

module.exports = withRouter(PreLeaveModalComponent);
