require('./globalPositionFinancialCardHeaderCol.scss');

// @ vendor
const PropTypes = require('prop-types');
const React = require('react');
const Immutable = require('immutable');
// @ helpers
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');
// @ core
const { getLanguage } = require('core/i18n').i18n;
// @ commons
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

class GlobalPositionFinancialCardHeaderCol extends React.Component {
    render() {
        const { legend, amount, currency, tooltipContent, tooltipInformationContent, keepSign, isWealth } = this.props;

        const amountObject = Immutable.fromJS({
            amount: keepSign ? amount : Math.abs(amount),
            currency
        });
        const plainAmountObject = getPlainAmountObject(amountObject);

        let amountSpan = (
            <span className="glb-position-financial-card-header-col__amount">
                <OneLineSymbolAmount
                    amountObject={plainAmountObject}
                    language={getLanguage()}
                    amountObjectFormatted={formatAmountObject(plainAmountObject, true)} />
            </span>
        );

        if (!!tooltipContent) {
            amountSpan = (
                <OKTooltip
                    content={tooltipContent}
                    target={amountSpan}
                />
            );
        }

        let informationToolTip = null

        if (tooltipInformationContent) {
            informationToolTip = <OKTooltip
                content={tooltipInformationContent}
                offset={[0, -10]}
                type="informative"
            />
        }

        const content = isWealth ?
        (
            <li className="glb-position-financial-card-header-col">
                <div className="glb-position-financial-card-header-col__amount-object">
                    {amountSpan}
                    {informationToolTip}
                </div>
                <div className="glb-position-financial-card-header-col__legend">
                    {legend}
                </div>
            </li>
        ) :
        (
            <li className="glb-position-financial-card-header-col">
                <div className="glb-position-financial-card-header-col__legend">
                    {legend}
                </div>
                <div className="glb-position-financial-card-header-col__amount-object">
                    {amountSpan}
                    {informationToolTip}
                </div>
            </li>
        );

        return content;
    }
}

GlobalPositionFinancialCardHeaderCol.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string,
    legend: PropTypes.string,
    tooltipContent: PropTypes.string,
    keepSign: PropTypes.bool,
    isWealth: PropTypes.bool
};

GlobalPositionFinancialCardHeaderCol.defaultProps = {
    isWealth: false
}

module.exports = GlobalPositionFinancialCardHeaderCol;
