const CHECKING_ACCOUNT = 'checking-account';
const CREDIT = 'credit';
const OPEN_YOUNG = 'open_young';
const PAYROLL_ACCOUNT = 'payroll_account';
const WELCOME_ACCOUNT = 'cuenta_bienvenida';
const WELCOME_PAYROLL_ACCOUNT = 'cuenta_bienvenida_nomina';
const HIRING_COMBINED_DEPOSITS = 'hiring_combined_deposits';
const HIRING_EIGHTEEN_MONTHS_RETENTION_DEPOSIT = 'hiring_eighteen_deposits_retention';
const HIRING_TWENTYFOUR_MONTHS_RETENTION_DEPOSIT = 'hiring_twentyfour_deposits_retention';

module.exports = {
    NOT_ALLOWED_TINK_AGGREGATOR: 'not_allowed_tink_aggregator_countries',
    ACCIDENTS_INSURANCE: 'accidents_insurance',
    ACCOUNT_DEBITS: 'account_debits',
    ACCOUNT_INTERVINENTS: 'account_interveners',
    ACCOUNTS_PORTABILITY_ENABLED: 'portability_accounts',
    ADOBE: 'adobe',
    ADVANCED_ORDER_SPANISH_STOCKS: 'advanced_order_spanish_stocks',
    AGENDA: 'agenda',
    AGGREGATOR_NEW_FEATURES: 'aggregator_new_features',
    AGGREGATOR_PSD2: 'aggregator_psd2',
    AGGREGATOR: 'aggregator',
    AGGREGATOR_CONSENTS: 'aggregator_consents',
    ALERTS: 'alerts',
    AMAZON_GIFT_CARD: 'amazon_gift_card',
    ASNEF: 'asnef',
    ATM_OFFICES: 'atm_offices',
    BASIC_ACCOUNT: 'web-basic-account-consents-off-enabled',
    BIZUM: 'bizum',
    BLOCK_DEVICE_SECURITY: 'block_device_security',
    BLOCKED_ACCOUNTS: 'locked_accounts',
    BRING_DIRECT_DEBITS: 'bring_direct_debits',
    BRING_MY_FUNDS_INFO_VIEW: 'bring_my_funds_info_view',
    BRING_MY_FUNDS: 'bring_my_funds',
    BRING_VALUES_TO_OK: 'bring_values_to_ok',
    BROKER_CAMBIOS_COMERCIALIZADORA: 'broker_cambios_comercializadora',
    BROKER_RISK_INFORMATION: 'broker-risk-information',
    BROKERAGE: 'brokerage',
    BUDGET: 'budget',
    BUILD_YOUR_PORTFOLIO_US_PERSON: 'wealth_build_portfolio_us_person',
    BUY_ETFS_HELP_LINK: 'buy_etfs_help_link',
    BUY_ETFS_SHOW_COST: 'wealth_etfs_buy_show_costs',
    BUY_STOCKS_CRF_INFO: 'buy_stocks_crf_info',
    BUY_STOCKS_W8_INFO: 'buy_stocks_w8_info',
    BUY_WARRANTS_SHOW_COST: 'wealth_warrants_buy_show_costs',
    CALLMEBACK_GENESYS: 'callmeback_genesys',
    CALLMEBACK: 'callmeback',
    CARD_CANCEL_DEFERRED_PURCHASE_ENABLED: 'card-cancel-deferred-purchase-enabled',
    CCV_HIRING_CHECK_DOCUMENT: 'ccv_hiring_check_document',
    CHAT: 'chat',
    CHECK_LEGALES_ENABLED: 'check_legales_enabled',
    CHECKING_ACCOUNT,
    CHEQUES: 'cheques',
    CHORES: 'chores',
    COMMERCIAL_MOBILE_INSURANCE: 'commercial_mobile_insurance',
    CONVENIENCE_TEST_LESS_SCORE_WARNING: 'convenience_test_less_score_warning',
    CONVENIENCE_TEST_US_PERSON: 'wealth_convenience_test_us_person',
    CREDIT_CARD_REVOLVING_PROCESS: 'credit_card_revolving_process',
    CREDIT,
    DENIAL_ACCOUNT_HIRING_BLOCKED_ACCOUNT: 'denial_account_hiring_blocked_account',
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS_SECOND_RENEWAL: 'deposito_13meses_renova',
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS: 'deposito_13meses',
    DEPOSITS_TOPBAR: 'deposits_topbar',
    DEPOSITS_INTEREST_BAND_COUNTRIES: 'deposit_interest_band',
    HIRING_MAIN_DEPOSITS_WITH_NEWBALANCE: 'hiring_main_deposits_with_newbalance',
    HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE: 'hiring_main_deposits_without_newbalance',
    DEPOSIT_RETENTION_EXCLUSIVE_OFFER: 'deposit_retention_exclusive_offer',
    RENEWAL_DEPOSIT: 'renewal_deposit',
    DEPOSIT_INFORMATIVE_WITHOUT_NEW_BALANCE: 'informative_deposit_wafer_without_newbalance',
    DIRECT_DEBITS: 'direct_debits',
    DISCLAIMER_MOBILE_INSURANCE: 'disclaimer_mobile_insurance',
    DISCOUNTED_MORTGAGE: 'discounted_mortgage',
    DISPLAY_CONVENIENCE_TEST_QUESTION_ZERO: 'display_convenience_test_question_zero',
    DIVERSIFIED_INVESTMENT_FUNDTRANSFER: 'diversified_investment_fundtransfer',
    DIVERSIFIED_INVESTMENT: 'diversified_investment',
    E_CARD: 'hire_ecard',
    EASY_LOAN_WAFFER: 'easy_loan_waffer',
    EVIDENCE_EASY_LOAN: 'evidence_easy_loan',
    ENABLED_CREDIT_CARD_FILTER: 'credit_card_filter',
    ENABLED_R42_CARDS: 'enabled_r42_cards',
    ENROL_DEVICES: 'enrol_devices',
    ES_COUNTRY: 'es_country',
    ESG_PRODUCT: 'esg_product',
    ETFS_BUY_US_PERSON: 'wealth_etfs_buy_us_person',
    ETFS_DETAILS: 'etfs_details',
    ETFS_LEGAL_DOCUMENTS: 'etfs_legal_documents',
    ETFS_SELL_SHOW_COST: 'wealth_etfs_sell_show_costs',
    EURIBOR: 'euribor',
    EVIDENCE_WEALTH_ROBO_HIRING: 'evidence_wealth_robo_hiring',
    EVIDENCE_WEALTH_SUITABILITY_TEST: 'evidence_wealth_suitability_test',
    FAQ: 'faq',
    FIOC_HIDING_ACCOUNTS_ENABLED: 'fioc_hiding_account_hiring',
    FISCAL_MESSAGE: 'fiscal_message',
    FUNDS_BUY_SHOW_COST: 'wealth_funds_buy_show_costs',
    FUNDS_BUY_US_PERSON: 'wealth_funds_buy_us_person',
    FUNDS_PERIODIC_CONTRIBUTION:'wealth_funds_periodic_contributions',
    FUNDS_SHOW_PURCHASE_ANNUAL_REPORT_DOCUMENT: 'wealth_funds_show_purchase_annual_report_document',
    FUNDS_SHOW_PURCHASE_PROSPECTUS_DOCUMENT: 'wealth_funds_show_purchase_prospectus_document',
    FUNDS_SHOW_TRANSFER_ANNUAL_DOCUMENT: 'wealth_funds_show_transfer_annual_report_document',
    FUNDS_SHOW_TRANSFER_PROSPECTUS_DOCUMENT: 'wealth_funds_show_transfer_prospectus_document: ',
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_ANNUAL_DOCUMENT: 'wealth_funds_show_portfolio_model_annual_report_document',
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_PROSPECTUS_DOCUMENT: 'wealth_funds_show_portfolio_model_prospectus_document',
    FUNDS_SHOW_PRESENTATION_DOCUMENT: 'wealth_funds_show_fund_presentation_document',
    FUNDS_SHOW_RECOMENDATION_REPORT: 'wealth_funds_show_recommendation_report_document',
    FUNDS_SHOW_RISK_ASSUMPTION_DOCUMENT: 'wealth_funds_show_risk_assumption_document',
    FUND_GLOSSARY: 'fund_glossary',
    FUNDS_RISK_INDICATOR: 'funds_risk_indicators',
    FUNDS_TRANSFER: 'funds_transfer',
    FUNDS_TRANSFER_SHOW_COSTS: 'wealth_funds_transfer_show_costs',
    FUNDS_TRANSFER_US_PERSON: 'wealth_funds_transfer_us_person',
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_NATIONAL_FUNDS: 'wealth_funds_show_prospectus_document_national_funds',
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_INTERNATIONAL_FUNDS: 'wealth_funds_show_prospectus_document_international_funds',
    FUNDS_SHOW_PRECONTRACTUAL_DOCUMENT_NATIONAL_FUNDS: 'wealth_funds_show_precontractual_document_national_funds',
    GC: 'gc',
    ECOMMERCE_INTERNET_PAYMENTS_ADHERED: 'internet_payments_adhered_commerce',
    ECOMMERCE_KEY: 'sca_ecommerce',
    ECOMMERCE_PGBANNER: 'e_commerce_pgbanner',
    HIDE_NEW_DEPOSIT: 'hide_new_deposit',
    HIDE_VIRTUAL_ADDITIONAL_CARD_CC: 'hide_virtual_additional_card_cc',
    HIRE_ACCOUNTS_ADD_DOCUMENTATION: 'hire_accounts_add_documentation',
    HIRE_CARD_REVOLVING_ENABLED: 'hire-card-revolving-enabled',
    HIRING_COMBINED_DEPOSITS,
    HIRING_EIGHTEEN_DEPOSITS: 'hiring_eighteen_deposits',
    HIRING_THIRTEEN_DEPOSITS: 'hiring_thirteen_deposits',
    HIRING_EIGHTEEN_DEPOSITS_RETENTION: 'hiring_eighteen_deposits_retention',
    HIRING_TWENTYFOUR_DEPOSITS_RETENTION: 'hiring_twentyfour_deposits_retention',
    HORMIGUERO_CARD: 'hire_el_hormiguero',
    INFO_POPUP_YOU_INVEST: 'wealth_info_popup_you_invest',
    INFO_POPUP_ROBOADVISOR: 'wealth_info_popup_roboadvisor',
    INSURANCE_IDD_REGULATION: 'insurance_idd_regulation',
    INSURANCE_LANDING_DISCRIMINATION: 'insurance_landing_discrimination',
    INSURANCE_MODAL_ICON: 'insurance_modal_icon',
    INSURANCE_NO_RELATED_LEGAL_CHECK: 'insurance_no_related_legal_check',
    INSURANCE_USABILITY_IMPROVEMENTS: 'insurance_usability_improvements',
    INSURANCES_OBLIGATIONS_DOCUMENT: 'insurance_obligations_document',
    INSURANCES_PERFORMANCE_LIMITS: 'actuation_limits',
    INSURANCES_RECOMMENDATION_FACTORS: 'insurance_recommendation_factors',
    INSURANCE_RELATED_LEGAL_CHECK: 'insurance_related_legal_check',
    INSURANCE_RELATED_OTP_OPTION: 'insurance_related_otp_option',
    INSURANCE_RELATED_PRE_APPRAISAL_NOTIFICATION: 'insurance_related_pre_appraisal_notification',
    INSURANCE_ADS_CIRCULAR: 'insurance_ads_circular',
    INSURANCE_EXTRA_SERVICE: 'insurance_extra_services',
    INSURANCES: 'home-insurance-enabled',
    INVESTMENT_PRODUCTS_LIST: 'investment_products_list',
    INVESTMENTS: 'investments',
    JOIN_HOLDERS: 'join_holders',
    LANDING_RISK_INDICATOR: 'landing_risk_indicator',
    LAST_DAYS_CMS_TAG: 'last_days_cms_tag',
    LAVANDA: 'lavanda',
    LIMIT_CREDIT_CARD: 'limit_credit_card',
    LOAN_CHECK_CIRBE_PERSONAL: 'loan_check_cirbe_personal',
    LOANS_MORTGAGES: 'loans_mortgages',
    LOANS_ARES: 'loans_ares',
    MAESTRO_CARD: 'hire_maestro_card',
    MANAGEMENT_FEES: 'management_fees',
    MARKETING_REPORTS: 'marketing_reports',
    MARKETS_AND_NEWS_FINANACES: 'markets_and_news_finances',
    MARKETS_AND_NEWS: 'markets_and_news',
    MB_CUSTOMER_MANAGEMENT_COUNTRIES: 'mb_customers',
    METALLIC_CARD: 'hire_metalica_card',
    METAL_CARD_VALIDATIONS: 'metal_card_validations',
    MGM: 'MGM',
    MIFID: 'mifid',
    MIFID_TEST_CONTRACTED_PRODUCTS_COHERENCE_CONTROL: 'wealth_mifid_test_contracted_products_coherence_control',
    MEDYSIF_WARRANT_PROVIDER: 'wealth_medysif_warrant_provider',
    MODIFY_SUITABILITY_TEST: 'modify_suitability_test',
    MORNINGSTAR_MOST_POPULAR: 'morningstar_most_popular',
    MORTGAGE_CANCEL_OPTION: 'mortgage_cancel_option',
    MORTGAGE_BONUSES: 'mortgage_bonuses',
    CARD_ACTIVATION_GREATER_THAN_ONE_YEAR: 'card_activation_greater_than_one_year',
    MORTGAGE_BONUSES_PAYROLL: 'mortgage_bonuses_payroll',
    MORTGAGE_BONUSES_LIFE_INSURANCE: 'mortgage_bonuses_life_insurance',
    MORTGAGE_BONUSES_REPSOL: 'mortgage_bonuses_repsol',
    MORTGAGE_BONUSES_HOME_INSURANCE: 'mortgage_bonuses_home_insurance',
    MORTGAGE_BONUSES_WEALTH: 'mortgage_bonuses_wealth',
    MORTGAGE_BONUSES_CARDS: 'mortgage_bonuses_cards',
    MORTGAGE_CONTRACTED_INSURANCE: 'mortgage_contracted_insurance',
    MORTGAGE_INSURANCES: 'mortgage-home-insurance-enabled',
    MORTGAGE_GUIDE: 'mortgage_guide',
    MORTGAGE_PAYMENT_TABLE_V2: 'mortgage_payment_table_v2',
    MORTGAGE_PAYMENT_TABLE: 'mortgage_payment_table',
    MORTGAGE_PRICING_WITHOUT_LTV: 'mortgage_pricing_without_ltv',
    NERUDA_CARD: 'hire_neruda',
    DIAMOND_CARD: 'hire_diamond',
    NEWS: 'news',
    NOT_ALLOWED_BRING_MY_FUNDS_COUNTRIES: 'not_allowed_bring_my_funds_countries',
    NOT_ALLOWED_BRING_MY_FUNDS_INFO_VIEW_COUNTRIES: 'not_allowed_bring_my_funds_info_view_countries',
    NOT_ALLOWED_CHECKING_URLS_COUNTRIES: 'not_allowed_checking_account_countries',
    NOT_ALLOWED_CONTRACT_URLS_COUNTRIES: {
        'checking-account': CHECKING_ACCOUNT,
        'combined-deposit': HIRING_COMBINED_DEPOSITS,
        'deposit-eighteen-months': 'hiring_eighteen_deposits',
        'duo-combined-deposit': CREDIT,
        'deposit': 'hiring_main_deposits_without_newbalance',
        'depositnm': 'hiring_main_deposits_with_newbalance',
        'deposit-eighteen-months-retention': HIRING_EIGHTEEN_MONTHS_RETENTION_DEPOSIT,
        'deposit-twentyfour-months-retention': HIRING_TWENTYFOUR_MONTHS_RETENTION_DEPOSIT,
        'deposittransfer': 'deposit_retention_exclusive_offer',
        //        'depositrenewal': 'renewal_deposit',
        'junior-account': OPEN_YOUNG,
        'open-young': OPEN_YOUNG,
        'payroll-account': PAYROLL_ACCOUNT,
        'personal-loan': CREDIT,
        'pre-approved-loan': CREDIT,
        'pre-granted-credit': CREDIT,
        'upgrade-to-payroll-account': PAYROLL_ACCOUNT
    },
    NOT_ALLOWED_LOANS_CONTRACTING_ARES_COUNTRIES: 'not_allowed_loans_contracting_ares_countries',
    NOT_ALLOWED_CARDS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES: 'cards_search_and_amount_filters',
    NOT_ALLOWED_CARDS_SEARCH_BY_TEXT_FILTERS_COUNTRIES: 'cards_search_by_text_filters',
    NOT_ALLOWED_CARDS_SEARCH_BY_AMOUNT_FILTERS_COUNTRIES: 'cards_search_by_amount_filters',
    NOT_ALLOWED_NEW_PG_WEALTH_COUNTRIES: 'new_pg_wealth',
    NOT_ALLOWED_ACCOUNTS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES: 'accounts_search_and_amount_filters',
    NOT_ALLOWED_OY_DASHBOARD_COUNTRIES: 'oy_dashboard',
    NOT_ALLOWED_PAYROLL_URLS_COUNTRIES: 'not_allowed_payroll_account_countries',
    NOT_ALLOWED_PRODUCT_CONTRACT_TNC_EMAIL_COUNTRIES: 'product_contract_tnc_email',
    NOT_ALLOWED_WEALTH_FUNDS_SELL_SHOW_COST_COUNTRIES: 'wealth_funds_sell_show_costs',
    NOT_SELECTED_PRODUCTS_TAB_BROKER: 'products_tab',
    EVIDENCE_OVERDRAFT: 'not_allowed_evidence_overdraft',
    NOTIFICATIONS_MANAGEMENT: 'notifications_management',
    OPEN_BROKERAGE_ACCOUNT_US_PERSON: 'wealth_open_brokerage_account_us_person',
    OPEN_CREDIT_CARD_AQUAMARINE_CARDART: 'open_credit_aquamarina_cardart',
    OPEN_DEBIT_CARD: 'hire_open_debit_card',
    OPEN_YOUNG_CARD: 'hire-card-openyoung-enabled',
    OPEN_YOUNG_PACK: 'open_young_pack',
    OPEN_YOUNG,
    OPENTRADER: 'opentrader',
    OPENTRADER_US_PERSON: 'wealth_opentrader_us_person',
    OTP_VITUAL_OFFICE_CHECK: 'otp_virtual_office_check',
    OVERDRAFT_LOAN_WAFFER: 'overdraft_loan_waffer',
    PACK_OPEN_YOUNG_ACCOUNT: 'open_young_pack',
    PAY_LATER: 'pay_later',
    PAYROLL_ACCOUNT,
    PENSION_PLANS_BRING_TO_OK_US_PERSON: 'wealth_pension_plans_bring_to_ok_us_person',
    PENSION_PLANS_CONTRIBUTION_US_PERSON: 'wealth_pension_plans_contribution_us_person',
    PENSION_PLANS_HIRE_US_PERSON: 'wealth_pension_plans_hire_us_person',
    PENSION_PLANS_PERIODIC_CONTRIBUTION_US_PERSON: 'wealth_pension_plans_periodic_contribution_us_person',
    PENSION_PLANS_TRANSFER_US_PERSON: 'wealth_pension_plans_transfer_us_person_countries',
    PERSONAL_LOAN_DRAGO: 'personal_loan_drago',
    PERSONAL_LOAN_WAFFER: 'personal_loan_waffer',
    PERSONETICS_VIRTUAL_OFFICE_CHECK: 'personetics_virtual_office_check',
    PERSONETICS: 'personetics',
    PFM_NEW_FEATURES: 'pfm_new_features',
    PFM: 'pfm_countries',
    PIN_ACCOUNT_COUNTRIES_NOT_ALLOWED: 'not_allowed_hire_passport_pin_countries',
    PIN_ACCOUNT: 'hire-accounts-pin-required-enabled',
    PLANS_OPERATIONS_WARNING_MODAL: 'plans_operations_warning_modal',
    PRE_CONTRACTUAL_EASY_LOAN_MAIL: 'pre_contractual_easy_loan_mail',
    PRE_CONTRACTUAL_NO_RELATED_INSURANCE_MAIL: 'pre_contractual_no_related_mail',
    PRE_CONTRACTUAL_OVERDRAFT_PROTECTION_MAIL: 'pre_contractual_overdraft_protection_mail',
    PRE_CONTRACTUAL_PAYROLL_LOAN_MAIL: 'pre_contractual_payroll_loan_mail',
    PRE_CONTRACTUAL_RELATED_INSURANCE_MAIL: 'pre_contractual_related_insurance_mail',
    PRE_CONTRACTUAL_RETAIL_LOAN_MAIL: 'pre_contractual_retail_loan_mail',
    PROVINCE: 'province',
    REGENERATE_SIGNATURE_KEY_WITH_BIOCATCH: 'regenerate_signature_key_with_biocatch',
    R42_BENEFITS_AND_INSURANCE: 'r42_benefits_and_insurance',
    R42_BENEFITS_AND_INSURANCE_ACCESS: 'r42_benefits_and_insurance_access',
    TRANSFERS_POPUP_BETWEEN_3K_25K: 'transfers_popup_between_3k_25k',
    TRANSFERS_POPUP_25K_MORE: '25k_more_transfers',
    TRANSFERS_SECURITY_WARNING: 'transfers_security_warning',
    INTERESTITIAL_LEGAL_MODAL: 'interstitial_legal_modal',
    LIFE_INSURANCE_WAFER: 'life_insurance_wafer',
    MESSAGE_HIRE_24H: 'message_hire_2_cards_24h',
    R42_BENEFITS_AND_INSURANCE_SECTION: 'r42_benefits_insurance_section',
    R42_TRAVEL_PLUS_OFF_HIRING: 'r42_travel_plus_off_hiring',
    REFERRALS: 'referrals',
    RENEWAL_DEPOSIT_ORDER_COUNTRIES: 'renewal_deposit_order',
    RENEWAL_DEPOSIT_EXTENDED_COUNTRIES: 'deposit_extended_offer',
    RETAIL_LOAN_WAFFER: 'retail_loan_waffer',
    REVOLVING_AFI_MODIFY_LIMIT: 'revolving_afi_modify_limit',
    REVOLVING_INE: 'revolving_v2_ine',
    RIGHTS_BUY_US_PERSON: 'wealth_rights_buy_us_person',
    RIGHTS_LINK: 'rights_link',
    RIGHTS_SELL_SHOW_COST: 'wealth_rights_sell_show_costs',
    RIGHTS_SHOW_COST: 'wealth_rights_buy_show_costs',
    ROBO_CONTRIBUTION_US_PERSON: 'wealth_roboadvisor_contribution_us_person',
    ROBO_GBI_US_PERSON: 'roboadvisor_gbi_us_person',
    ROBO_HIRE_US_PERSON: 'wealth_roboadvisor_hire_us_person',
    ROBO_HIRE_WITH_TRANSFER: 'robo_compra_traspaso',
    ROBO_PERIODIC_CONTRIBUTION_US_PERSON: 'wealth_roboadvisor_periodic_contribution_us_person',
    ROBO_PRECONTRACT: 'robo_precontract',
    ROBO_SHOW_IMPLICIT_COSTS: 'wealth_robo_show_implicit_costs_contracting_screen',
    ROBO_ZERO_COMMISSION: 'robo_zero_commission',
    ROBO: 'robo',
    SAVINGS: 'savings',
    SCA_BANNER: 'sca_banner',
    SECURITIES: 'securities',
    SELL_ETFS_HELP_LINK: 'sell_etfs_help_link',
    SELL_STOCKS_SHOW_COST: 'wealth_stocks_sell_show_costs',
    SENDING_PN_OTP: 'sending_pn_otp',
    SENDING_SIGNATURE_KEY: 'sending_signature_key',
    SETBACKS: 'setbacks',
    SHIPPING_ADDRESS_USER: 'shipping_address_user',
    SHOW_GLOBAL_POSITION_VENTURE_CAPITAL_FUNDS: 'wealth_show_global_position_venture_capital_funds',
    SHOW_SUITABILITY_TEST_EXPIRATION_NOTICE: 'wealth_show_suitability_test_expiration_notice',
    SIGN_SUITABILITY_TEST_NOT_SUITABLE: 'sign_suitability_test_not_suitable',
    SOLIDARITY_CARD_CONTRACT: 'solidarity_card_contract',
    SOLIDARITY: 'solidarity',
    STEP_FOUR_POPULATION_ENABLED: 'home-insurance-public-population-enabled',
    STOCKS_BUY_SHOW_PROFITABILITY: 'wealth_stocks_buy_show_profitability',
    STOCKS_BUY_US_PERSON: 'wealth_stocks_buy_us_person',
    STOCK_DETAILS: 'stock_details',
    STOCK_SHOW_COST: 'wealth_stocks_buy_show_costs',
    SUIABILITY_TEST_US_PERON: 'suitability_test_us_person',
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST: 'sustainability_questions_suitability_test',
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST_COUNTRIES: 'wealth_suitability_test_sustainability_questions',
    TAXATION: 'taxation',
    TOPUP_MOBILE: 'topup_mobile',
    TPPS_ENABLED: 'tpps_enabled',
    TRANSFER_EXTERNAL_SECURITIES: 'transfer-external-securities',
    TRANSFER_ON_SAVINGS_ACCOUNT_CTA: 'transfer_on_savigs_account_cta',
    TRANSFER_PENSION_PLANS: 'broker-transfer-pension-plans',
    TRANSFER_SECURITIES_TO_OK_US_PERSON: 'wealth_transfer_securities_to_ok_us_person',
    TRAVEL_PLUS_R42: 'travel_plus_R42',
    TREND_INVESTORS: 'trend_investors',
    TREND: 'trend',
    TUTORIAL: 'tutorial',
    TYPE_ETFS: 'type_etfs',
    TYPE_FUNDS: 'type_funds',
    TYPE_ALTERNATIVE_FUNDS: 'wealth_hire_venture_capital_funds',
    TYPE_OF_ROAD: 'type_of_road',
    TYPE_PLANS: 'type_plans',
    TYPE_STOCKS: 'type_stocks',
    TYPE_WARRANTS: 'type_warrants',
    URL_DISCOUNT: 'url_discount',
    VANITY_CARD: 'hire_vanity_fair',
    VIRTUAL_CREDIT_CARD_GLOBAL: 'hire-card-virtual-credit-enabled',
    VIRTUAL_CREDIT_CARD: 'hire_virtual_credit_card',
    VIRTUAL_DEBIT_CANCER_CARDART: 'virtual_debit_cancer_cardart',
    VIRTUAL_DEBIT_CARD: 'virtual-debit-card-hiring-enabled',
    VIRTUAL_DEBIT_PRIDE_CARDART: 'virtual_debit_pride_cardart',
    WARRANTS_BUY_US_PERSON: 'wealth_warrants_buy_us_person',
    WARRANTS_DETAILS: 'warrants_details',
    WARRANTS_SELL_SHOW_COST: 'wealth_warrants_sell_show_costs',
    WEALTH_BUILD_PORTFOLIO_SHOW_COSTS: 'wealth_build_portfolio_show_costs',
    WB_CRF: 'wb_crf',
    WEALTH_OTP_PHONE: 'wealth_otp_phone',
    WEALTH_FUNDS_PERIODIC_CONTRIBUTIONS: 'wealth_funds_periodic_contributions',
    WEB_GC_ENABLED: 'web-gc-enabled',
    WEB_INACTIVE_ACCOUNT_ENABLED: 'web-inactive-account-enabled',
    WEB_OY_DASHBOARD_ENABLED: 'web-oy-dashboard-enabled',
    WELCOME_ACCOUNT,
    WELCOME_PAYROLL_ACCOUNT,
    YOUR_INVESTMENTS: 'your_investments',
    PAYROLL_LOAN_LEGAL_CHECK: 'payroll_loan_legal_check',
    EASY_LOAN_LEGAL_CHECK: 'easy_loan_legal_check',
    OVERDRAFT_PROTECTION_LEGAL_CHECK: 'overdraft_protection_legal_check',
    RETAIL_LOAN_LEGAL_CHECK: 'retail_loan_legal_check',
    OPEN_MARKETS_PERSONAL_DATA_CESSION: 'open_markets_personal_data_cession',
    OPEN_MARKETS_PERSONAL_DATA_INTERSTICIAL: 'open_markets_personal_data_intersticial',
    HIDE_INDIVIDUAL_UPLOAD_DOCUMENTS_HOLDER: 'hide_individual_upload_documents_holder',
    STANDALONE_RELATED_INSURANCE: 'standalone_related_insurance',
    DALI_OPTION_GAP_ACCESS: 'dali_option_gap_access',
    MB_TRANSFERS_COUNTRIES: 'mb_transfers',
    MB_ACCOUNTS_COUNTRIES: 'mb_accounts',
    NEW_PLASTIC_MAESTRO: 'new_plastic_maestro',
    NOT_ALLOWED_WEALTH_BROKER_IA_COUNTRIES: 'not_allowed_wealth_broker_ia_countries',
    NOT_ALLOWED_NEW_PREAPPROVAL_LOAN_FLOW_COUNTRIES: 'new_preapproval_loan_flow',
    NOT_ALLOWED_PREAPROVED_CARDS_WAFFER_COUNTRIES: 'preaproved_cards_waffer',
    NOT_ALLOWED_EVIDENCE_WEALTH_SUITABILITY_TEST_COUNTRIES: 'not_allowed_evidence_wealth_suitability_test_countries',
    NOT_ALLOWED_NEW_EASY_LOAN_FLOW_COUNTRIES: 'new_easy_loan_flow',
    NOT_ALLOWED_EVIDENCE_EASY_LOAN_MODELC: 'not_allowed_evidence_easy_loan_modelC_countries',
    NOT_ALLOWED_EVIDENCE_CIRBE_MORTGAGE_COUNTRIES: 'evidence_cirbe_mortgage',
    NOT_ALLOWED_EVIDENCE_PARTIAL_LOAN_REPAYMENT_COUNTRIES: 'evidence_partial_loan_repayment',
    NOT_ALLOWED_NEW_RETAIL_LOAN_FLOW_COUNTRIES: 'new_retail_loan_flow',
    NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES: 'new_personal_loan_flow',
    NOT_ALLOWED_NEW_CREDIT_CARDS_FLOW_COUNTRIES : 'new_credit_cards_flow',
    NOT_ALLOWED_NEW_MIXED_MORTGAGE_5_YEARS_COUNTRIES : 'new_mixed_mortgage_5_years',
    
    WEALTH_SEPBLAC_BLOCKED_ACCOUNTS: 'wealth_sepblac_blocked_accounts',
};
