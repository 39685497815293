// @ vendors
const React = require('react');
const { PropTypes } = require('prop-types');
const { connect } = require('react-redux');
// @ components
const { LoadingSection } = require('commonsComponents/loadingSection');
const SignaturePattern = require('components/signaturePattern/signaturePattern').SignaturePattern;
// @ constants
const {
    MULTIPLE_OPERATIONS,
    GDPR_ID,
} = require('constants/operationTypes');
const {
    NOT_ALLOWED_NEW_EASY_LOAN_FLOW_COUNTRIES,
    EVIDENCE_EASY_LOAN,
    EVIDENCE_OVERDRAFT,
    NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES
} = require('constants/flagBasedFeatures');

class GDPRSignaturePattern extends React.Component {
    render() {
        const {
            customComponentDidUpdate,
            immGDPRConsents,
            multiOTPMessageCode,
            multiOTPGDPRMessageCode,
            operationParams,
            operationType,
            printTitleValue,
            isWealth,
            customPrintDocument,
            bodyClass,
            handleSMSClick,
            printDocumentTranslations,
            customEventCategory,
            pdfToShow,
            isPdfToShowBase64,
            pdfToShowFetching,
            pdfToShowError,
            isOverDraft,
            isEasyLoanTypeAB,
            immContractEasyLoan,
            immPreGrantedCredit,
            immFlagBasedFeatures,
            newOperationProcessId,
            onResendClick,
            taggingOnKeyPress,
            taggingOnChangeRadioButton,
        } = this.props;

        if (immGDPRConsents.get('isFetching')) {
            return <LoadingSection />;
        } else if (immGDPRConsents.get('fetchSuccess')) {
            if (
                operationType !== GDPR_ID &&
                immGDPRConsents.get('otp') &&
                immGDPRConsents.get('consents').size > 0
            ) {
                const newEasyLoanFeatureFlag = immFlagBasedFeatures.get(NOT_ALLOWED_NEW_EASY_LOAN_FLOW_COUNTRIES);
                const newEasyLoanFF = immFlagBasedFeatures.get(NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES);
                const evidenceEasyLoanFeatureFlag = immFlagBasedFeatures.get(EVIDENCE_EASY_LOAN);
                const evidenceOverdraftFlag = immFlagBasedFeatures.get(EVIDENCE_OVERDRAFT);
                const operationProcessIdPolicy = !!immContractEasyLoan && evidenceEasyLoanFeatureFlag 
                    ? immContractEasyLoan.get('operationProcessIdPolicy') 
                    : !!immPreGrantedCredit && evidenceOverdraftFlag && immPreGrantedCredit.get('operationProcessIdPolicy');
                const gdprParams = {
                    serviceCode: GDPR_ID,
                };

                let requestMultiParams;
                let params = {};
                let loanOTPParams;
                 if (newEasyLoanFF) {
                    loanOTPParams = isOverDraft && newOperationProcessId && {
                        operationProcessId: newOperationProcessId,
                        evidenceType: "SIGN_DOCUMENT"
                    };
                } else {
                    loanOTPParams = isOverDraft && operationProcessIdPolicy && {
                        operationProcessId: operationProcessIdPolicy,
                        evidenceType: "SIGN_DOCUMENT"
                    };
                }
                params = {
                    ...loanOTPParams
                }

                if (Array.isArray(operationParams)) {
                    operationParams.push(gdprParams);
                    requestMultiParams = operationParams;
                } else {
                    requestMultiParams = [
                        Object.assign({ serviceCode: operationType }, operationParams),
                        gdprParams
                    ];
                }

                params.requestMulti = requestMultiParams;
                // TODO: fix signature pattern action to avoid this override
                if (multiOTPGDPRMessageCode || multiOTPMessageCode) {
                    params.id = multiOTPGDPRMessageCode || multiOTPMessageCode;
                }
                return (
                    <SignaturePattern
                        customComponentDidUpdate={customComponentDidUpdate}
                        operationParams={params}
                        operationType={MULTIPLE_OPERATIONS}
                        printTitleValue={printTitleValue}
                        isWealth={isWealth}
                        customPrintDocument={customPrintDocument}
                        bodyClass={bodyClass}
                        printDocumentTranslations={printDocumentTranslations}
                        onSMSInputClick={handleSMSClick}
                        customCategoryEvent={customEventCategory}
                        pdfToShow={pdfToShow}
                        isPdfToShowBase64={isPdfToShowBase64}
                        pdfToShowError={pdfToShowError}
                        pdfToShowFetching={pdfToShowFetching}
                        isOverDraft={isOverDraft}
                        isEasyLoanTypeAB={isEasyLoanTypeAB}
                        onResendClick={onResendClick}
                        taggingOnKeyPress={taggingOnKeyPress}
                        taggingOnChangeRadioButton={taggingOnChangeRadioButton}
                    />
                );
            } else {
                 const newEasyLoanFF = immFlagBasedFeatures.get(NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES);
                let params = operationParams;
                if (Array.isArray(operationParams)) {
                    params = {
                        requestMulti: operationParams
                    };
                    if (newEasyLoanFF) {
                        if (multiOTPMessageCode) {
                            params.operationProcessId = newOperationProcessId,
                                params.evidenceType = "SIGN_DOCUMENT"
                            params.id = multiOTPMessageCode;
                        }
                    } else {
                        if (multiOTPMessageCode) {
                            params.id = multiOTPMessageCode;
                        }
                    }
                }
                if (operationType === undefined) {
                    return (<div></div>);
                } else {
                    return (
                        <SignaturePattern
                            customComponentDidUpdate={customComponentDidUpdate}
                            operationParams={params}
                            operationType={operationType}
                            printTitleValue={printTitleValue}
                            customPrintDocument={customPrintDocument}
                            bodyClass={bodyClass}
                            printDocumentTranslations={printDocumentTranslations}
                            onSMSInputClick={handleSMSClick}
                            customCategoryEvent={customEventCategory}
                            pdfToShow={pdfToShow}
                            isPdfToShowBase64={isPdfToShowBase64}
                            pdfToShowError={pdfToShowError}
                            pdfToShowFetching={pdfToShowFetching}
                            isOverDraft={isOverDraft}
                            isEasyLoanTypeAB={isEasyLoanTypeAB}
                            onResendClick={onResendClick}
                            taggingOnKeyPress={taggingOnKeyPress}
                            taggingOnChangeRadioButton={taggingOnChangeRadioButton}
                        />
                    );
                }
            }
        } else return (<div></div>);
    }
}

GDPRSignaturePattern.defaultProps = {
    isWealth: false,
    customPrintDocument: null,
    bodyClass: '',
    printDocumentTranslations: null,
    handleSMSClick: undefined,
    customEventCategory: undefined,
    isPdfToShowBase64: true,
    onResendClick: () => {},
    taggingOnKeyPress: () => {},
    taggingOnChangeRadioButton: () => {},
}

GDPRSignaturePattern.propTypes = {
    // public props
    customComponentDidUpdate: PropTypes.func,
    customEventCategory: PropTypes.string,
    multiOTPMessageCode: PropTypes.number,
    multiOTPGDPRMessageCode: PropTypes.number,
    immGDPRConsents: PropTypes.object.isRequired,
    pdfToShow: PropTypes.string,
    isPdfToShowBase64: PropTypes.bool,
    pdfToShowFetching: PropTypes.bool,
    pdfToShowError: PropTypes.bool,
    operationType: PropTypes.number,
    handleSMSClick: PropTypes.func,
    operationParams: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    printTitleValue: PropTypes.string,
    isWealth: PropTypes.bool,
    isOverDraft: PropTypes.bool,
    isEasyLoanTypeAB: PropTypes.bool,
    customPrintDocument: PropTypes.string,
    bodyClass: PropTypes.string,
    printDocumentTranslations: PropTypes.object,
    immContractEasyLoan: PropTypes.object,
    immPreGrantedCredit: PropTypes.object,
    immFlagBasedFeatures: PropTypes.object,
    onResendClick: PropTypes.func,
    taggingOnKeyPress: PropTypes.func,
    taggingOnChangeRadioButton: PropTypes.func,
};

module.exports.constructor = GDPRSignaturePattern;
module.exports.GDPRSignaturePattern = connect(
    state => ({
        immGDPRConsents: state.gdprConsents,
        immContractEasyLoan: state.contractEasyLoan,
        immPreGrantedCredit: state.contractPreGrantedCredit,
        immFlagBasedFeatures: state.flagBasedFeatures
    })
)(GDPRSignaturePattern);
